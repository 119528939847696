.ant-table-cell{
   width: fit-content;
}

.ant-table-tbody{
   background-color: white;
}
.search-col{
   display: flex;
   justify-content: space-between;
   align-items: flex-end;
  
}

@media only screen and (max-width:600px) {
   .search-col{
    margin-top: 10px;
   }
 }