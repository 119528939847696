.pagination::-webkit-scrollbar{
    display:none;
}
.numbers::-webkit-scrollbar{
    display: none;
}

.content::-webkit-scrollbar{
    display: none;
}


@media screen and (max-width: 1080px) {
    .numbers{
        width: fit-content;
        box-sizing: content-box;
    }
}