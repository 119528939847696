
@media screen and (max-width: 480px){
    .body{
        flex-direction: column;
    }
    .header{
        padding-left:0 !important;
    }
    .paginationY{
        flex-direction: row !important;
        margin-bottom: 10px;
    }
}